/* Fonts */

$fa-font-path: "../webfonts";

$body-text-font: 'Noto Sans', Helvetica, Arial, sans-serif;
$heading-font: 'Merriweather', Georgia, serif;
$body-line-height: 1.5;
$body-font-size: 16px;

/* Colors */
$body-bg-color:                   rgb(254, 249, 245);
$body-text-color:                 #3d3d3d;

$brand-dark-blue:                 rgb(51, 87, 97); // #335761
$brand-orange:                    rgb(243, 115, 71); // #f37347
$brand-blue:                      rgb(85, 130, 150); // #558296

$brand-light-blue:                rgb(45, 165, 230); // #2DA5E6
$brand-danger:                    rgb(200, 0, 50); // #C80032

$brand-scnd-blue-light:           rgb(197, 231, 250);
$brand-scnd-orange-medium:        rgb(235, 161, 139);
$brand-scnd-orange-light:         rgb(255, 216, 190);
$brand-scnd-orange-lighter:       rgb(255, 238, 221);
$brand-scnd-green:                rgb(98, 146, 122);
$brand-scnd-purple-light:         rgb(153, 153, 180);
$brand-scnd-purple:               rgb(104, 89, 117);

$brand-paywall-basic:             rgb(45, 165, 230);

$brand-paywall-premium:           rgb(110, 90, 150);

$form-input-border-color:         rgb(206, 212, 218); // #ced4da;

$link-color:                      $brand-blue;
$link-hover-color:                $brand-orange;

$link-hover-bg:                   rgb(243, 115, 71, 0.06);

$brand-orange-light-bg:           rgb(243, 115, 71, 0.08);

$pure-black:                      #000;
$pure-white:                      #fff;

$sand-bg:                         rgb(253, 243, 238);
$light-sand-bg:                   rgb(254, 249, 245);


$sidebar-width-xl:                 360px;
$sidebar-width-lg:                 320px;
