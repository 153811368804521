/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// Aspect Ratio
@mixin overlay-alfa($name, $color, $transparency) {
	.overlay-#{$name}-alfa-#{$transparency * 5}:before {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: -1;
		content: "";
		left: 0;
		top: 0;
		background-color: rgba($color, calc($transparency / 20));
	}

}

@mixin viewport-height($height) {
	.h-#{$height * 5}vh {
		height: #{$height * 5}vh;
	}
}
