.footer {
	background-color: $brand-dark-blue;
	color: rgba($pure-white, 0.8);

	&__links {
		padding: 3rem 0;

		h3 {
			margin: 0 0 1.5rem 0;
			font-size: 1.125rem;
			color: rgba($pure-white, 0.8);
		}

	}

	&__link-list {
		list-style: none;
		margin: 0 0 1.5rem 0;
		padding: 0;
	}

	&__link-item {
		margin: 0;
		padding: 0 0 0.125rem 0;

		a {
			font-size: 0.875rem;
			text-decoration: none;
			color: rgba($pure-white, 0.8);

			&:hover {
				color: $pure-white;
				text-decoration: underline;
			}
		}
	}

	&__copyright {
		border-top: solid 1px rgba(255,255,255,0.15);
		.col {
			padding: 1rem 0;
			font-size: 0.875rem;
			text-align: center;
		}
	}
}



