.content-book-list {
	padding-top: 0.5rem;
	padding-bottom: 3rem;
}

.book-item {
	display: block;
	padding: 1.5rem 0;
	text-align: center;
	text-decoration: none;


	&:hover {
		.book-item__text {
			color: $brand-orange;
		}
	}

	&__image {
		margin-bottom: 1rem;
		padding: 10% 20%;
		text-align: center;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);

		img {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}

	}

	&__text {
		color: $brand-dark-blue;

		.btn {
			margin: 1rem 0;
		}
	}

	&__title {
		font-weight: 700;
	}

	&__author {
		font-weight: 400;
	}

	&__price {
		font-weight: 700;
	}

	&__price--sale {
		color: $brand-orange;
	}
	&__price--from {
		color: #aaa;
		text-decoration: line-through;
	}

}
