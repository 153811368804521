.topbar {
	background: $pure-white;
	border: none;
	padding: 0.5rem 0;

	&__inner {
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
	}

	&__right {
		text-align: right;
	}

	&__logo {
		display: block;
		max-width: 240px;
	}

	&__list {
		list-style: none;
		padding: 0;
		margin: 0;

		.topbar__link {
			display: inline-block;

			> a {
				display: block;
				padding: 0.125rem 0.25rem;
				font-size: 0.9375rem;
				color: $brand-blue;
				text-decoration: none;

				&:hover {
					color: $brand-orange;
					text-decoration: underline;
				}
			}

			&.topbar__link--button {
				> a {
					padding: 0.125rem 0.5rem;
					color: $pure-white;
					background-color: $brand-orange;

					&:hover {
						color: $pure-white;
						text-decoration: none;
					}
				}
			}

		}


	}
}

@media (min-width: 480px) {
	.topbar {
		&__logo {
			max-width: 300px;
		}
	}
}

