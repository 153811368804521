.page-title--parallax {
	padding: 3rem 0;
	.container-fluid {
		z-index: 200;
		h1 {
			font-size: calc(1.325rem + 0.9vw);
			margin-bottom: 0;
			color: rgba(255,255,255,0.8);
			text-align: center;
		}
	}
}




