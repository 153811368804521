@keyframes breathe {
	0% {
		background-size: 100% auto;
	}
	50% {
		background-size: 110% auto;
	}
	100% {
		background-size: 100% auto;
	}
}

:root {
	--animate-delay: 0.5s;
}

.hero {
	.carousel {
		height: 12.5rem;
	}

	.carousel-inner,
	.carousel-item {
		height: 100%;
		background-color: transparent;
	}

	.carousel-caption {
		position: absolute;
		top: 50%;
		left: 50%;
		right: auto;
		bottom: auto;
		width: 90%;
		transform: translate(-50%, -50%);
		z-index: 100;
		padding: 0;

		.shift-left {
			margin-left: -3rem;
		}

		.shift-right {
			margin-right: -3rem;
		}

	}

	.carousel-caption__link {
		display: block;
		width: 100%;
		height: 100%;
		padding: 2rem 0;
		text-decoration: none;
		text-align: center;

		h2 {
			font-family: "Noto Sans", Helvetica, Arial, sans-serif;
			font-size: 2rem;
			color: rgba(255, 255, 255, 0.9);
			white-space: nowrap;
		}

		h3 {
			font-family: "Noto Sans", Helvetica, Arial, sans-serif;
			font-size: 1.25rem;
			color: rgba(255, 255, 255, 0.9);
			white-space: nowrap;
		}

		&:hover {
			h2 {
				color: rgba(255, 255, 255, 1);
			}

			h3 {
				color: rgba(255, 255, 255, 1);
			}
		}

	}

	.bg-image {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: 50% 50%;
		animation: breathe 30s linear infinite;
	}

	.carousel-item:before {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 1;
		content: "";
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.4);
	}
}

@media(min-width: 992px) {
	.hero {
		.carousel {
			height: 15rem;
		}

		.carousel-caption {
			.shift-left {
				margin-left: -4.5rem;
			}

			.shift-right {
				margin-right: -4.5rem;
			}
		}

		.carousel-caption__link {
			h2 {
				font-size: 2.25rem;
			}

			h3 {
				font-size: 1.5rem;
			}
		}
	}


}

@media(min-width: 1200px) {
	.hero {
		.carousel {
			height: 18.75rem;
		}

		.carousel-caption {

			.shift-left {
				margin-left: -6rem;
			}

			.shift-right {
				margin-right: -6rem;
			}
		}

		.carousel-caption__link {
			h2 {
				font-size: 3rem;
			}

			h3 {
				font-size: 2.25rem;
			}
		}
	}

}




