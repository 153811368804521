.content-magazine-list {
	padding: 3rem 0;
}

.magazine-item {
	display: block;
	margin-bottom: 2.5rem;
	color: $brand-blue;
	text-align: center;
	text-decoration: none;
	&:hover {
		color: $brand-orange;
	}

	&__image {
		text-align: center;
		padding: 10% 20%;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		img {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&__text {
		padding: 0.5rem 0;
	}
	&__title {
		font-size: 1rem;
		font-weight: 700;
		color: $brand-dark-blue;
	}

}



