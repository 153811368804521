
.content-library-list {
	padding: 0.5rem 3rem;

	.divider {
		height: 0.375rem;
		margin: 0 0 2rem 0;
		border-top: solid 1px rgba($brand-blue, 0.25);
		border-bottom: solid 1px rgba($brand-blue, 0.25);
	}
}


.library-item {
	display: block;
	padding: 1.5rem 0;
	text-align: center;
	text-decoration: none;
	color: $brand-dark-blue;

	&:hover {
		color: $brand-orange;
	}

	&__image {
		margin-bottom: 0.5rem;
		padding: 0 10%;
		text-align: center;
		background: transparent;
	}
	&__text {
		//
	}
	&__title {
		font-weight: 700;
	}

}




