/* colors */

/* ~~~~ primary ~~~~ */
.brand-dark-blue {
	color: $brand-dark-blue;
}

.brand-blue {
	color: $brand-blue;
}

.brand-light-blue {
	color: $brand-light-blue;
}

.brand-orange {
	color: $brand-orange;
}

/* ~~~~ secondary ~~~~ */

.brand-scnd-blue-light {
	color: $brand-scnd-blue-light;
}

.brand-scnd-orange-medium {
	color: $brand-scnd-orange-medium;
}

.brand-scnd-orange-light {
	color: $brand-scnd-orange-light;
}

.brand-scnd-orange-lighter {
	color: $brand-scnd-orange-lighter;
}

.brand-scnd-purple-light {
	color: $brand-scnd-purple-light;
}

.brand-scnd-purple {
	color: $brand-scnd-purple;
}


/* ~~~~ primary background ~~~~ */

.brand-dark-blue-bg {
	background-color: $brand-dark-blue;
}

.brand-blue-bg {
	background-color: $brand-blue;
}

.brand-blue-darker-bg {
	background-color: darken($brand-blue, 5%);
}

.brand-light-blue-bg {
	background-color: $brand-light-blue;
}

.brand-light-blue-darker-bg {
	background-color: darken($brand-light-blue, 5%);
}

.brand-orange-bg {
	background-color: $brand-orange;
}

.brand-orange-darker-bg {
	background-color: darken($brand-orange, 5%);
}

/* ~~~~ secondary background ~~~~ */

.brand-scnd-blue-light-bg {
	background-color: $brand-scnd-blue-light;
}

.brand-scnd-orange-medium-bg {
	background-color: $brand-scnd-orange-medium;
}

.brand-scnd-orange-light-bg {
	background-color: $brand-scnd-orange-light;
}

.brand-scnd-orange-lighter-bg {
	background-color: $brand-scnd-orange-lighter;
}

.brand-scnd-purple-light-bg {
	background-color: $brand-scnd-purple-light;
}

.brand-scnd-purple-bg {
	background-color: $brand-scnd-purple;
}

.brand-scnd-purple-bg {
	background-color: $brand-scnd-purple;
}


/* ~~~~ special bg ~~~~ */

.brand-sand {
	background-color: $sand-bg;
}

.brand-lightsand {
	background-color: $light-sand-bg;
}


.color-white {
	color: $pure-white;
}

.color-dark {
	color: $body-text-color;
}

.color-white-bg {
	background-color: $pure-white;
}

.color-dark-bg {
	background-color: $body-text-color;
}

// overrule text color on buttons
.btn-secondary {
	color: $pure-white !important;
}

.btn-info {
	color: $pure-white !important;
}

// fix borders and shadow
.btn {
	border: none;
	&:focus {
		box-shadow: none;
	}
	&:active:focus {
		box-shadow: none;
	}
}


// transparency (step = 5)
@for $t from 1 through 19 {
	@include overlay-alfa('brand-blue', $brand-blue, $t);
}

@for $t from 1 through 19 {
	@include overlay-alfa('brand-orange', $brand-orange, $t);
}

@for $t from 1 through 19 {
	@include overlay-alfa('black', $pure-black, $t);
}


