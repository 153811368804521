.content-review-single {
	padding: 3rem 0;
}

.review-single {
	&__review-author {
		margin-top: 3rem;
		padding: 1.5rem;
		background-color: $brand-orange-light-bg;
	}

	&__book {
		margin-top: 3rem;
		h3 {
			margin-bottom: 1rem;
			font-size: 1.5rem;
			font-weight: 400;
		}
		.btn {
			margin-top: 1rem;
		}
	}
	&__cover {
		margin-bottom: 1.5rem;
		width: 120px;
	}

	&__header {
		//
	}

	&__title {
		//
	}

	&__subtitle {
		margin-bottom: 1.5rem;
		color: $brand-blue;
	}

	&__author-link {
		display: block;
		margin-bottom: 1.5rem;
		color: $brand-orange;
	}
}
