.paywall {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 99999;

	&.paywall--active {
		display: block;
	}

	&__backdrop {
		display: block;
		height: 40vh
	}

	&__container {
		position: relative;
		width: 100%;
		height: 100%;
		text-align: center;
		background-color: $pure-white;
		box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);


	}

	&__content {
		padding-top: 10rem;

		h3 {
			margin-bottom: 1.5rem;
			font-size:1.25rem;
			color: $brand-orange;
		}
	}

	&__join-link {
		margin: 1.5rem 0;
	}

	.brand-arrow {
		position: absolute;
		height: auto;

		.brand-arrow__text {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			color: $pure-white;

		}

		&--right {
			top: 8rem;
			left: 0;
			width: 10rem;

			.brand-arrow__text {
				padding-right: 20%;
				font-size: 1.125rem;
			}
		}

		&--down {
			top: 0;
			left: 0;
			right: 0;
			width: 6.25rem;
			margin-left: auto;
			margin-right: auto;

			.brand-arrow__text {
				padding-bottom: 20%;
				font-size: 1rem;
			}
		}
	}
}

@media(min-width: 992px) {

	.paywall {
		.paywall__content {
			padding-top: 5rem;

		}
	}
}
