@charset "UTF-8";

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ v1.0.0 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

// Bootstrap
@import "bootstrap-vars";
@import "bootstrap/bootstrap";
// @import "bootstrap-custom";

// Lara5
@import "variables";

// Fonts
@import "fonts";

// components
@import "components";

// vendor
@import "vendor/animate/animate";

