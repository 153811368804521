/* merriweather-regular - latin */
@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('../webfonts/merriweather-v30-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/merriweather-v30-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merriweather-italic - latin */
@font-face {
	font-family: 'Merriweather';
	font-style: italic;
	font-weight: 400;
	src: local(''),
	url('../webfonts/merriweather-v30-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/merriweather-v30-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* merriweather-700 - latin */
@font-face {
	font-family: 'Merriweather';
	font-style: normal;
	font-weight: 700;
	src: local(''),
	url('../webfonts/merriweather-v30-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/merriweather-v30-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}