
blockquote {
	position: relative;
	margin: 2.5rem 0;
	padding: 1.25rem 2.5rem 1.25rem 3.75rem;
	font-family: $heading-font;
	font-style: italic;
	font-size: 1.25rem;
	background-color: $brand-orange-light-bg;
	color: $brand-dark-blue;

	&:before {
		position: absolute;
		top: 1rem;
		left: 1.25rem;
		content: "\201C";
		font-family: Georgia, serif;
		font-size: 3.75rem;
		font-weight: 400;
		line-height: 1;
		color: $brand-blue;
	}
}

@media(min-width: 768px) {
	blockquote {
		font-size: 1.375rem;
	}
}


@media(min-width: 992px) {
	blockquote {
		padding: 2rem 6rem 2rem 7.5rem;
		font-size: 1.5rem;
		&:before {
			font-size: 5.5rem;
			top: 1.375rem;
			left: 1.5rem;
		}
	}
}
