/* ~~~~~~~~ OffCanvas - Nav ~~~~~~~~ */


/* Off Canvas */
.sidemenu {
	&.offcanvas-start {
		width: 365px;
	}

	&.offcanvas-dark-bg {
		background-color: $brand-dark-blue;
	}

	.offcanvas-header {
		border-bottom: solid 1px rgba(255, 255, 255, 0.12);

		h3 {
			margin: 0.5rem 0;
		}

		.nav-tabs {
			border: none;
		}

		.nav-tabs {
			.nav-link {
				font-family: Merriweather, serif;
				border: none;
				background: none;
				color: rgb(85, 130, 150);

				&.hover {
					color: rgb(243, 115, 71);
				}

				&.active {
					color: $pure-white;
				}
			}
		}
	}

	.offcanvas-body {
		padding: 0;

		.tab-content {
			height: 100%;
		}

		.tab-content .tab-pane {
			height: 100%;
		}

	}
	.offcanvas-footer {
		margin-top: 1.25rem;
		padding: 1rem;
	}
}


/* cMenu */
.cmenu {
	height: 100%;

	div {
		display: flex;
	}

	&.cmenu--visible {
		visibility: visible;
	}

	&__canvas {
		width: 100%;
		flex-direction: column;
		height: 100%;
		box-shadow: 4px 0 10px 0 rgba(0, 0, 0, .4);


	}

	&__content {
		position: relative;
		overflow-x: hidden;
		height: 100%;
		-webkit-overflow-scrolling: touch;


	}

	&__list {
		padding: 0 0 4rem 0;
		margin: 0;
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		overflow-y: scroll;
		will-change: transform;
		visibility: hidden;

		list-style: none;
		font-size: 14px;
		line-height: 16px;

		scrollbar-color: transparent;
		scrollbar-width: none;

		&.cmenu--visible {
			visibility: visible
		}

		&::-webkit-scrollbar-track {
			box-shadow: none;
			border-radius: 0;
			background-color: transparent;
		}

		&::-webkit-scrollbar {
			width: 0;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 0;
			box-shadow: none;
			background-color: grey;
		}

		li {
			list-style: none;
			margin: 0;
			padding: 0;
			word-wrap: break-word;
		}

	}

	&__separator {
		margin: 0.375rem 0 !important;
		border-bottom: solid 1px rgba(255, 255, 255, 0.15);
	}

	&__item {
		padding: 0.75rem 1.25rem 0.75rem 2rem;

		&.cmenu-header {
			padding-left: 1rem;
			color: $brand-orange;
			font-weight: 700;
			background-color: darken($brand-dark-blue, 3%);
		}

	}

	a.cmenu__item {
		display: flex;
		position: relative;
		text-decoration: none;
		color: rgba(255, 255, 255, 0.75);

		&:hover {
			background-color: darken($brand-dark-blue, 5%);
			text-decoration: none;
			color: #fff;
		}

		&:visited {
			text-decoration: none;
			color: #fff;
		}

		&.cmenu__item-header {
			padding-left: 1rem;
			color: #fff;
			font-weight: 700;
		}

		&:not(.cmenu__icon) {
			i {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 24px;
				color: #8ca0b4;
			}
		}

		&.cmenu__icon {
			padding-left: 4.5rem;

			i {
				position: absolute;
				top: 0.675rem;
				left: 2rem;
				font-size: 1.5rem;
				color: rgba(255, 255, 255, 0.75);
			}
		}

		&.cmenu__back-button {
			margin-bottom: 10px;
			padding-left: 36px;
			border-top: solid 1px rgba(255, 255, 255, 0.12);
			border-bottom: solid 1px rgba(255, 255, 255, 0.12);
			// background-color: darken($brand-dark-blue, 3%);

			&:hover {
				background-color: darken($brand-dark-blue, 5%);
			}

			i {
				left: 16px;
				right: auto;
			}
		}
	}
}


/* transitions */
.cmenu .cmenu__shift--left,
.cmenu .cmenu__shift--right,
.cmenu .cmenu__shift--none {
	-webkit-transition: transform 250ms ease-in-out;
	-moz-transition: transform 250ms ease-in-out;
	-ms-transition: transform 250ms ease-in-out;
	-o-transition: transform 250ms ease-in-out;
	transition: transform 250ms ease-in-out
}

.cmenu .cmenu__shift--none {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	-ms-transform: translateX(0);
	-o-transform: translateX(0);
	transform: translateX(0)
}

.cmenu .cmenu__shift--left {
	-webkit-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	-o-transform: translateX(-100%);
	transform: translateX(-100%)
}

.cmenu .cmenu__shift--right {
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%)
}


/* ~~~~~~~~ Offcanvas - Search Filters ~~~~~~~~ */

.search-filters {
	.offcanvas {
		.offcanvas-body {
			.btn-apply-filter {
				width: 100%;
				margin-top: 1.25rem;
			}
		}
	}
}

@media (min-width: 768px) {
	.search-filters {
		.offcanvas {
			visibility: visible;
			position: relative;
			background: none;
			border: none;
			justify-content: end;
			z-index: auto;

			.offcanvas-header {
				display: none;
			}
			.offcanvas-body {
				padding-top: 0;
			}
		}
	}
}

@media (max-width: 767px) {
	.search-filters {
		.offcanvas {
			width: 90% !important;
			max-width: 500px;
		}
	}

	.search-filters {
		.offcanvas-start-lg {
			top: 0;
			left: 0;
			border-right: 1px solid rgba(0, 0, 0, .2);
			transform: translateX(-100%);
		}
	}

}