.cta {
	padding: 3rem 0;
	color: $pure-white;
	background-color: $brand-blue;

	&__title {
		text-align: center;
		h3 {
			font-size: 1.5rem;
			margin-bottom: 2.5rem;
			color: $pure-white;
		}

	}

	&__join {
		text-align: center;
	}

	&__join-text {
		margin-bottom: 1.5rem;
	}

	&__join-button {
		padding: 0.5rem 3.75rem;
	}

	.image-link {
		margin-bottom: 2rem;
	}
}