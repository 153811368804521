$grid-gutter-width: 2rem;

$primary: rgb(51, 87, 97);
$secondary: rgb(243, 115, 71);
$info: rgb(85, 130, 150);
$danger: rgb(200, 0, 50);
$light: rgb(255, 255, 255);

$link-color: rgb(85, 130, 150);
$link-hover-color: rgb(243, 115, 71);


$h1-font-size: 2rem;
$h2-font-size: 1.375rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1.125rem;
$h5-font-size: 1.125rem;
$h6-font-size: 1rem;

$headings-font-weight: 400;
$headings-margin-bottom: 1rem;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$input-btn-padding-y: .375rem;
$input-btn-padding-x: 1.5rem;

$input-btn-focus-box-shadow: none;


$form-switch-color: rgb(243, 115, 71);
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}' stroke='%23fff' stroke-width='0.5'/></svg>");

$form-switch-focus-color: rgb(243, 115, 71);
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}' stroke='%23fff' stroke-width='0.5'/></svg>");

$form-switch-checked-color: rgb(243, 115, 71);
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}' stroke='%23fff' stroke-width='0.5'/></svg>");


$font-sizes: (
		1: 0.125rem,
		2: 0.25rem,
		3: 0.375rem,
		4: 0.5rem,
		5: 0.625rem,
		6: 0.75rem,
		7: 0.875rem,
		8: 1rem,
		9: 1.125rem,
		10: 1.25rem,
		11: 1.375rem,
		12: 1.5rem,
		13: 1.625rem,
		14: 1.75rem,
		15: 1.875rem,
		16: 2rem,
		17: 2.125rem,
		18: 2.25rem,
		19: 2.375rem,
		20: 2.5rem,
		21: 2.625rem,
		22: 2.75rem,
		23: 2.875rem,
		24: 3rem,
);

$spacer: 1rem;
$spacers: (
		0: 0,
		1: $spacer * .25,
		2: $spacer * .5,
		3: $spacer * .75,
		4: $spacer,
		5: $spacer * 1.25,
		6: $spacer * 1.5,
		7: $spacer * 1.75,
		8: $spacer * 2,
		9: $spacer * 2.25,
		10: $spacer * 2.5,
		11: $spacer * 2.75,
		12: $spacer * 3,
		13: $spacer * 3.25,
		14: $spacer * 3.5,
		15: $spacer * 3.75,
		16: $spacer * 4,
		17: $spacer * 4.25,
		18: $spacer * 4.5,
		19: $spacer * 4.75,
		20: $spacer * 5,
		21: $spacer * 5.25,
		22: $spacer * 5.5,
		23: $spacer * 5.75,
		24: $spacer * 6,
		25: $spacer * 6.25,
		26: $spacer * 6.5,
		27: $spacer * 6.75,
		28: $spacer * 7,
		29: $spacer * 7.25,
		30: $spacer * 7.5,
);

$aspect-ratios: (
		"1x1": 100%,
		"2x1": calc(1 / 2 * 100%),
		"3x1": calc(1 / 3 * 100%),
		"3x2": calc(2 / 3 * 100%),
		"4x1": calc(1 / 4 * 100%),
		"4x3": calc(3 / 4 * 100%),
		"16x9": calc(9 / 16 * 100%),
		"2x3": calc(3 / 2 * 100%),
		"3x4": calc(4 / 3 * 100%)
);

