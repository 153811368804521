.product-link {
	text-align: center;
	text-decoration: none;
	color: $body-text-color;

	.img-container {
		text-align: center;
		padding: 0;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);

	}

}

.content-productsingle {
	.img-container {
		text-align: center;
		padding: 10% 20%;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		img {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}
	}
}

.product-cta {
	height: 100%;
}

.price-from {
	color: #aaa;
	text-decoration: line-through;
}

.productsingle {
	.carousel-control-next,
	.carousel-control-prev {
		filter: invert(100%);
	}
}
