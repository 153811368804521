
.paytag {

	position: absolute;
	top: 5px;
	left: 5px;
	right: auto;
	padding: 0.125rem 0.375rem;
	font-size: 0.75rem;
	font-weight: 600;

	color: $pure-white;
	opacity: 1;

	&--small {
		top: 0;
		left: 0;
		right: auto;
		font-size: 12px;
		line-height: 1;
		padding: 2px 6px;
		opacity: 0.9;
	}

	&--basic {
		background-color: $brand-paywall-basic;
	}

	&--premium {
		background-color: $brand-paywall-premium;
	}
	&--none {
		display: none;
	}
}

.card-link {
	position: relative;
	display: block;
	text-decoration: none;
	color: $body-text-color;

	&:hover {
		color: $body-text-color;
	}

	h3 {
		margin-top: 0.25rem;
		font-size: 1rem;
	}

	&:not(.has-image) {
		.paytag {
			top: 0;
			right: 0;
			left: auto;
		}
	}

	.card-link__tags {
		display: flex;
		flex-direction: row;
		list-style: none;
		margin: 0 0 1rem 0;
		padding: 0;
		.card-link__tag {
			margin-right: 0.625rem;
			padding: 0.1875rem 0.5rem;
			font-size: 0.875rem;
			border: solid 1px rgba($brand-blue, 0.15);
		}
	}

}


@media(min-width: 768px) {

	.card-link {

		h3 {
			font-size: 1.125rem;
		}

		&:hover {
			background: $link-hover-bg;
		}

	}

}

.image-link {
	position: relative;
	display: block;

	&.zoom {
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			transition: transform 250ms ease-in-out 0s;
		}

		&:hover img {
			transform: scale(1.02);
		}

	}

	.image-link__title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		padding: 20px;
		background-image: linear-gradient(rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.7));
	}

	h3 {
		margin: 0;
		line-height: 1.6;
		color: $pure-white;
	}

	&--large {
		h3 {
			font-size: 1.5rem;
		}
	}
	&--small {
		h3 {
			font-size: 1.125rem;
		}
	}

}

.post-list {
	margin-botom: 3rem;

	&__item {
		padding: 1rem 0;
		border-bottom: solid 1px rgba($brand-blue, 0.15);
	}

	&__item-content {
		h3 {
			marginright: 3.5rem;
			margin-bottom: 0.5rem;
			color: $brand-dark-blue;
		}
		p {
			margin: 0;
			font-size: 0.875rem;
		}
	}

}




