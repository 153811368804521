.bread-crumb {
	display: none;
}

@media(min-width: 768px) {
	.bread-crumb {
		display: block;
		padding: 0.5rem 0;
		.breadcrumb {
			.breadcrumb-item {
				font-size: 0.875rem;
				color: rgb(140,150,160);
				a {
					color: rgb(140,150,160);
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
