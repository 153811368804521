.content-book-single {
	padding: 3rem 0;

	.carousel-control-next,
	.carousel-control-prev {
		filter: invert(100%);
	}
}


.book-single {

	&__title {

		h1 {
			margin-bottom: 1.5rem;
			font-size: 2rem;
		}

		h2 {
			margin-bottom: 1.5rem;
			font-size: 1.5rem;
			color: $brand-orange
		}

		&.book-single__title--sm {
			display: block;
		}
		&.book-single__title--lg {
			display: none;
		}

	}

	&__images {

		&.book-single__images--lg {
			display: none;
			.book-single__image {
				margin-bottom: 1rem;
			}
		}

		&.book-single__images--sm {
			display: block;
			margin-bottom: 3rem;
		}

	}
	&__image {
		text-align: center;
		padding: 10% 20%;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		img {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&__thumb {
		display: block;
		margin-bottom: 1rem;
		padding: 10% 20%;
		text-align: center;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		img {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&__cta {
		padding: 1.5rem 0;
		background: $brand-blue;
	}

	&__cta-col {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	&__cta-item {
		height: 100%;
		padding: 3rem;
		background-color: $pure-white;

		img {
			margin-bottom: 3rem;
		}

		ul {
			margin-bottom: 2.5rem;
		}
	}

	&__cta-price {
		margin: 3rem 0;
		font-size: 1.75rem;
		font-weight: 700;
	}

}

@media(min-width: 768px) {
	.book-single {

		&__images {

			&.book-single__images--lg {
				display: block;
			}

			&.book-single__images--sm {
				display: none;
			}

		}

		&__title {
			&.book-single__title--sm {
				display: none;
			}
			&.book-single__title--lg {
				display: block;
			}
		}

		&__cta-item {
			padding: 2rem;
		}
	}
}


@media(min-width: 992px) {
	.book-single {
		&__cta-item {
			padding: 3.5rem;
		}
	}
}
