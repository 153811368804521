
.content-join {
	padding: 1rem 0 3rem 0;
}


.progbar {
	margin-bottom: 2rem;

	&__container {
		//
	}

	&__steps {
		display: flex;
		width: 100%;
		height: 2.5rem;
	}

	&__step {
		position: relative;
		width: 33.33%;
		text-align: center;
	}

	&__line {
		position: absolute;
		top: 15px;
		left: 0;
		right: 0;
		width: 100%;
		height: 2px;
		background-color: rgba($brand-orange, 0.5);

		&.progbar__line--first {
			left: auto;
			width: 50%;
		}

		&.progbar__line--last {
			right: auto;
			width: 50%;
		}
	}

	&__number {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 2.5rem;
		height: 2rem;
		margin: 0 auto;
		padding: 0.125rem 0.5rem 0 0;
		font-size: 1.125rem;
		font-weight: 700;

		text-align: center;
		color: $pure-white;

		background-image: url(../img/arrow-right-blue.svg);
		background-repeat: no-repeat;
		background-size: contain;

		a {
			display: block;
			width: 100%;
			height: 100%;
			text-decoration: none;
			color: rgba(255, 255, 255, 0.9);

			&:hover {
				color: rgba(255, 255, 255, 1);
				text-decoration: none;
			}
		}


	}

	.progbar__step {
		&.progbar__step--active {
			.progbar__number {
				background-image: url(../img/arrow-right-orange.svg);
			}
		}

		&.progbar__step--done {
			.progbar__number {
				background-image: url(../img/arrow-right-orange.svg);
			}
		}
	}

	&__text-items {
		display: flex;
		width: 100%;
	}

	&__text {
		color: $brand-dark-blue;
		width: 33.33%;
		text-align: center;


		&.progbar__text--active {
			color: $brand-orange;
		}
	}
}


.subscr-card {
	margin-bottom: 1.5rem;
	background-color: rgba(255, 255, 255, 0.8);

	&__container {
		height: 100%;
	}

	&__title {
		font-family: $body-text-font !important;
		font-size: 1.75rem;
		color: $pure-white;
		margin: 0;
		padding: 1rem 0;
		text-align: center;
	}

	&__content {
		padding: 0 1rem;
	}

	&__pricing {
		padding: 2rem 0;
		text-align: center;

		.subscr-card__pricing-text {
			display: inline-block;
			padding-right: 0.5rem;
			font-size: 0.875rem;
			font-weight: 300;
		}

		.subscr-card__pricing-price {
			display: inline-block;
			font-size: 2rem;
			font-weight: 600;
		}

	}

	&__description {
		height: 7rem;
		overflow: hidden;
		text-align: center;
		padding-bottom: 2rem;
		font-size: 0.9375rem;
		font-weight: 600;
		color: $brand-blue;
	}

	&__button {
		text-align: center;

		.btn {
			display: block;
			width: 100%;
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
		}
	}

	&__usp {
		padding: 1rem 0 2rem 0;
	}

	&__usp-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__usp-list-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.5rem 0;
		border-bottom: solid 1px #eee;
		color: #999;

		&.subscr-card__usp-list-item--active {
			color: $body-text-color;
		}

		i {
			width: 1rem;
			text-align: center;

			&.fa-times {
				color: #aaa;
			}

			&.fa-check {
				color: rgb(0, 170, 240);
			}
		}
	}

	&__anchor-link {
		display: block;
		margin-top: 1.5rem;
		text-align: center;
	}

	&.subscr-card--default {

		border-bottom: solid 1px rgba($brand-blue, 0.25);
		border-left: solid 1px rgba($brand-blue, 0.25);
		border-right: solid 1px rgba($brand-blue, 0.25);

		.subscr-card__title {
			background-color: $brand-blue;
		}
	}

	&.subscr-card--highlight {

		border-bottom: solid 1px $brand-orange;
		border-left: solid 1px $brand-orange;
		border-right: solid 1px $brand-orange;

		.subscr-card__title {
			background-color: $brand-orange;
		}

		.subscr-card__button {

		}
	}

}

@media (min-width: 768px) {
	.collapse.collapse-open-lg {
		display: block;
		height: auto !important;
		visibility: visible;
	}

}

@media (min-width: 992px) {
	.subscr-card {
		&.subscr-card--default {
			&.subscr-card--first {
				border-right: none;
			}

			&.subscr-card--last {
				border-left: none;
			}
		}
	}
}

.step-one-text {
	padding: 5rem 0;

	&__card {
		margin-bottom: 2rem;
		background-color: #fff;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15)
	}

	&__card-inner {
		padding: 1.25rem;

		h2 {
			//
		}

		h4 {
			color: $brand-orange;
		}

		p {
			margin: 0;
		}

	}
}


/* ~~~~~~~~~~~~~~~~ Comparison Table START ~~~~~~~~~~~~~~~~ */

.comparison {
	margin-left: -0.675rem;
	margin-right: -0.675rem;

	> h2 {
		margin: 4rem 0 1rem 0;
		font-size: 2rem;
		color: rgb(243, 115, 71);
		text-align: center;
	}

	> h4 {
		margin-bottom: 2rem;
		font-family: "Noto Sans", Helvetica, Arial, sans-serif;
		font-size: 2.25rem;
		font-weight: 300;
		text-align: center;
		letter-spacing: 4px;
	}

	&__container {
		overflow-x: auto;
	}

	&__swipe {
		margin-bottom: 0.75rem;
		text-align: right;
	}

	&__swipe img {
		margin-right: 1rem;
		opacity: 0.6;
	}

	&__table {
		min-width: 800px;
		background-color: #fff;

		thead {
			background-color: rgb(51, 87, 97);

			th {
				padding-top: 1.5rem;
				color: #fff;

				&:first-child {
					background-color: rgb(41, 77, 87);
				}
			}

			th
			th.colw1 {
				width: 16%;
			}

			th.colw2,
			th.colw3,
			th.colw4 {
				width: 28%;
			}

			th h2,
			th h4 {
				margin-bottom: 0.5rem;
				font-family: "Noto Sans", Helvetica, Arial, sans-serif;
				font-weight: 600;
				color: #fff;
			}

			h2 {
				font-size: 1.5rem;
			}
		}

		tbody {
			h3 {
				margin-bottom: 0.375rem;
				font-family: Noto Sans, Helvetica, Arial, sans-serif;
				font-size: 1.125rem;
				font-weight: 600;
				color: rgb(243, 115, 71);
			}

			td {
				font-size: 0.875rem;
			}

			th {
				&:first-child {
					background-color: #f8fafa;
				}

				&.comparison__col1 {
					font-weight: 600;
					color: rgb(85, 130, 150);

					span {
						color: rgb(243, 115, 71);
					}
				}
			}


			.fa-check-circle {
				font-size: 1.5rem;
				color: rgb(85, 130, 150);
			}

			.fa-horizontal-rule {
				margin-left: 0.25rem;
				font-size: 0.75rem;
				color: #ccc;
			}

			tr.blue-row {
				background-color: #f0f5f8;
				th:first-child {
					background-color: #eaf0f6;
				}
			}


		}

	}
}

.table-scroll {
	position: relative;
	width: 100%;
	z-index: 1;
}

.table-scroll table {
	width: 100%;
}

.table-scroll th:first-child {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 2;
}

.table-scroll thead th:first-child {
	z-index: 5;
}

@media(min-width: 768px) {
	.comparison {
		&__table {
			thead th.colw1,
			thead th.colw2,
			thead th.colw3,
			thead th.colw4 {
				width: 25%;
			}
		}

	}
}

/* ~~~~~~~~~~~~~~~~ Comparison Table START ~~~~~~~~~~~~~~~~ */


.step-two-header,
.step-three-header {
	text-align: center;

	&__title {
		margin-bottom: 1.5rem;
		padding: 0.75rem 0;
		border-top: solid 1px rgba($brand-blue, 0.4);
		border-bottom: solid 1px rgba($brand-blue, 0.4);
		font-size: 1.75rem;
		font-weight: 500;
		// text-transform: uppercase;
		color: $brand-dark-blue;
	}
}

.wizard {
	.col {
		text-align: center;
	}

	&__prod-title {
		margin-bottom: 0.75rem;
		font-family: $body-text-font;
		color: $brand-dark-blue;
		font-size: 1.25rem;
		text-align: center;

		span {
			color: $brand-orange;
			font-weight: 600;
		}

		&.wizard__prod-title--disabled {
			color: #aaa;

			span {
				color: #aaa;
			}
		}
	}

	&__prod-card {
		max-width: 18.75rem;
		min-height: 27.5rem;
		margin: 0 auto 2rem auto;
		padding: 1.5rem 1rem;
		background-color: $pure-white;
		box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.15);
		text-align: center;

		h4 {
			font-family: $body-text-font;
			font-size: 1.25rem;
			font-weight: 600;
			color: $brand-dark-blue;
		}

		&.wizard__prod-card--selected {
			border: solid 1px rgb(243, 115, 71);
		}

		&.wizard__prod-card--disabled {
			background-color: #f6f8fa;

			h4 {
				margin-top: 3rem;
				font-size: 1rem;
				font-weight: 600;
				line-height: 2;
				color: #ccc;
			}
		}

		h5 {
			font-family: $body-text-font;
			font-size: 1.25rem;
			font-weight: 600;
			color: $brand-blue;
		}

		figure {
			max-width: 180px;
			margin: 0 auto 1rem auto;
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&__pricing {
		min-height: 2.5rem;
		font-size: 1.5rem;
		font-weight: 600;
		color: $body-text-color;

		span {
			font-size: 0.875rem;
			font-weight: 300;
		}

	}

	&__pricing--small {
		min-height: 2.25rem;
		font-size: 0.875rem;
		font-weight: 400;
		color: #777;
	}

	&__pricing--discount {
		min-height: 2.25rem;
		font-size: 1rem;
		font-weight: 500;
		color: $brand-orange;
	}

	.btn {
		width: 100%;
	}
}