
.filterbar {
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);

	.col {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		text-align: right;
	}
}
