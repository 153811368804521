
@media(min-width: 992px) {
	.has-sidebar {
		> :nth-child(1) {
			width: calc(100% - $sidebar-width-lg);
		}
		> :nth-child(2) {
			width: $sidebar-width-lg;
		}
	}
}

@media(min-width: 1200px) {
	.has-sidebar {
		> :nth-child(1) {
			width: calc(100% - $sidebar-width-xl);
		}
		> :nth-child(2) {
			width: $sidebar-width-xl;
		}
	}
}
