/* ~~~~~~~~ Page ~~~~~~~~ */
.page-title {

	h1 {
		margin-bottom: 0;
		font-size: calc(1.325rem + 0.9vw);
		text-align: center;
	}

	&--wide {
		padding: 1.5rem 0;
		background-color: $pure-white;
		box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);

		h1 {
			color: $brand-blue;
		}
	}

	&--with-filter {
		margin: 1.25rem 0 0 0;
	}

}

.content-page-single {
	padding: 3rem 0;
}

