/* noto-sans-300 - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	src: local(''),
	url('../webfonts/noto-sans-v27-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/noto-sans-v27-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-regular - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('../webfonts/noto-sans-v27-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/noto-sans-v27-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-italic - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 400;
	src: local(''),
	url('../webfonts/noto-sans-v27-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/noto-sans-v27-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-500 - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	src: local(''),
	url('../webfonts/noto-sans-v27-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/noto-sans-v27-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-600 - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 600;
	src: local(''),
	url('../webfonts/noto-sans-v27-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/noto-sans-v27-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700 - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	src: local(''),
	url('../webfonts/noto-sans-v27-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../webfonts/noto-sans-v27-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}