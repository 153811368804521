.content-podcast-list {
	padding: 3rem 0;
}

.streams {

	margin-top: 2rem;
	padding: 1.25rem 1.25rem 0 1.25rem;
	background-color: rgba($brand-blue, 0.15);

	.stream {
		display: block;
		position: relative;
		margin-bottom: 1.25rem;
		padding: 1.125rem;
		text-decoration: none;
		border-radius: 5px;

		&__icon {
			position: absolute;
			top: 1.125rem;
			left: 1.125rem;
			width: 2rem;
			height: 2rem;
			color: #fff;
			svg {
				fill: #fff;
			}
		}

		&__text {
			padding-left: 3rem;
			font-family: $heading-font;
			font-size: 1rem;
			color: $pure-white;
			text-align: center;
		}

		&.stream--spotify {
			background-color: rgb(29, 208, 93);
		}

		&.stream--apple {
			background-color: rgb(174, 78, 219);
		}

		&.stream--google {
			background-color: rgb(227, 65, 51);
		}
	}
}

.podcast-list {
	margin-top: 3rem;
}

.podcast-item {

	h2 {
		margin-bottom: 1.25rem;
		font-size: 1.25rem;
	}
	&__meta {
		margin-top: 1rem;

		h3 {
			font-size: 1.25rem;
		}
	}
}

.widget-podcast {
	&__image {
		margin-bottom: 1.25rem;
	}
}

