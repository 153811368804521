$icon-yellow: #FFBE00;
$icon-blue: #0093D2;
$icon-purple: #781eb4;
$icon-rwed: #af0032;


.content-search-list {
	padding: 1rem 0 3rem 0;
}

.search-item {
	padding: 1rem 0;

	&__icon {
		padding-top: 0.5rem;
		text-align: center;
	}

	&__title {
		margin: 0.25rem 4rem 0.75rem 0 !important;
		font-style: 1.25rem;
		font-weight: 400;
	}

	&__lead {
		p {
			margin: none;
		}
	}

}


.landing-card {

	&__title {
		position: relative;
		padding: 0.5rem 0 0 3.75rem;

		i {
			position: absolute;
			top: 0;
			left: 0;
			font-size: 2.25rem;
		}
	}

	&__description {
		margin-bottom: 2rem;
		padding: 2rem;
		border-top: solid 1px $icon-purple;
		border-bottom: solid 1px $icon-purple;
		background-color: rgba(243, 115, 71, 0.06);

		p {
			margin: 0;
		}
	}
}

.icon {
	&--yellow {
		color: $icon-yellow;
	}

	&--blue {
		color: $icon-blue;
	}

	&--purple {
		color: $icon-purple;
	}

	&--red {
		color: #af0032;
	}

}


.content-cat {
	.readmore {
		height: 2rem;
		text-decoration: none;
	}

}

