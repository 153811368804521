.js-preloader {
	background: $pure-white;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: 1;
	z-index: 9998;
	transition: opacity 0.6s ease-out;

	&.loading {
		opacity: 0;
	}

	&.loaded {
		opacity: 0;
		display: none;
	}

}

.spinner {
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	text-indent: -9999em;
	background-color: transparent;
	border: 5px solid #eee;
	border-radius: 50%;

	&:after {
		position: absolute;
		content: "";
		top: 0px;
		left: 0px;
		width: 40px;
		height: 40px;
		padding: 2px;
		border-width: 2px;
		border-style: solid;
		border-color: $brand-blue transparent transparent transparent;
		border-radius: 50%;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		animation: ringrotate 0.8s infinite cubic-bezier(0.5, 0.49, 0.49, 0.49);
		-webkit-animation: ringrotate 0.8s infinite cubic-bezier(0.5, 0.49, 0.49, 0.49);
	}
}

@keyframes ringrotate {
	0% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}

	100% {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}

@-webkit-keyframes ringrotate {
	0% {
		-webkit-transform: rotateZ(0deg);
		transform: rotateZ(0deg);
	}

	100% {
		-webkit-transform: rotateZ(360deg);
		transform: rotateZ(360deg);
	}
}