.content-library-single {
	padding: 3rem 0;

	.carousel-control-next,
	.carousel-control-prev {
		filter: invert(100%);
	}
}


.library-single {

	&__title {

		h1 {
			margin-bottom: 1.5rem;
			font-size: 2rem;
		}

		h2 {
			margin-bottom: 1.5rem;
			font-size: 1.5rem;
			color: $brand-orange
		}

		&.library-single__title--sm {
			display: block;
		}
		&.library-single__title--lg {
			display: none;
		}

	}

	&__images {

		&.library-single__images--lg {
			display: none;
			.library-single__image {
				margin-bottom: 1rem;
			}
		}

		&.library-single__images--sm {
			display: block;
			margin-bottom: 3rem;
		}

	}
	&__image {
		text-align: center;
		padding: 10% 20%;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		img {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&__thumb {
		display: block;
		margin-bottom: 1rem;
		padding: 10% 20%;
		text-align: center;
		background: $pure-white;
		border-radius: 2px;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
		img {
			box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&__cta {
		margin: 2.5rem auto 1rem auto;
		text-align: center;
		.btn {
			display: block;
			margin: 0 auto 1rem auto;
			padding: 0.5rem 3.75rem;
			max-width: 240px;
		}
	}

}

@media(min-width: 768px) {
	.library-single {

		&__images {

			&.library-single__images--lg {
				display: block;
			}

			&.library-single__images--sm {
				display: none;
			}

		}

		&__title {
			&.library-single__title--sm {
				display: none;
			}
			&.library-single__title--lg {
				display: block;
			}
		}

		&__cta-item {
			padding: 2rem;
		}
	}
}


.issuu {
	iframe {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		border: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
		z-index: 999999;
	}
}