.searchbar {
	height: 0;
	overflow: hidden;
	width: 100%;
	background-color: $pure-white;
	transition: all 0.1s ease-out;
	box-shadow: 0 0 5px rgba($pure-black, 0.15);

	.row {
		padding: 3rem 0;
	}

	&__form {
		position: relative;
		opacity: 0;
		transition: all 0.2s ease-out;

		input[type=search] {
			padding-left: 3rem;
			background-color: $pure-white;
			border-radius: 100px;
		}
		.btn {
			position: absolute;
			top: 50%;
			left: 0;
			padding: 0 0.75rem;
			background-color: transparent;
			transform: translateY(-50%)
		}
	}

	&.searchbar--open {
		opacity: 1;
		height: 8.75rem;

		.searchbar__form {
			opacity: 1;
		}
	}

}

.navbar.navbar-scrolled {
	.searchbar {
		top: 58px;
	}
}
