.content-magazine-single {

	padding: 3rem 0;

	h3 {
		margin-bottom: 2rem;
		color: $brand-blue;
	}

}

.magazine-single {
	&__featured {
		width: 100%;
		margin: 0 0 1rem;
	}

}

@media(min-width: 360px) {
	.magazine-single {
		&__featured {
			float: right;
			width: 120px;
			margin: 0 0 1.25rem 1.25rem;
		}
	}
}

@media(min-width: 480px) {
	.magazine-single {
		&__featured {
			float: right;
			width: 180px;
			margin: 0 0 1.25rem 1.25rem;
		}
	}
}

@media(min-width: 768px) {
	.magazine-single {
		&__featured {
			float: right;
			width: 240px;
			margin: 0 -8rem 2rem 2rem;
		}
	}
}

@media(min-width: 1200px) {
	.magazine-single {
		&__featured {
			float: right;
			width: 300px;
			margin: 0 -11.875rem 2rem 2rem; // negative right margin, two columns
		}
	}
}

.content-magazine-cta {
	padding: 1.5rem;
	background-color: $brand-blue;
}


.magazine-cta {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;

	&__inner {
		height: 100%;
		padding: 3.75rem;
		background-color: $pure-white;
		h3 {
			margin-bottom:1.25rem;
			font-size: 1.25rem;
			color: $brand-orange;
		}

		&__button {
			text-align: center;
		}
	}
}

@media(min-width: 768px) {
	.magazine-cta__inner {
		padding: 3.75rem 2rem;
	}
}
@media(min-width: 992px) {
	.magazine-cta__inner {
		padding: 3.75rem;
	}
}