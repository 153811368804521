
h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font;
	font-weight: 400;
	color: $brand-dark-blue;
}

a {
	text-decoration-thickness: 1px;
	&:hover {
		text-decoration-thickness: 1px;
	}
}


.noto-sans {
	font-family: $body-text-font !important;
}

.merriweather {
	font-family: $heading-font !important;
}
